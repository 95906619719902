.img1{
    width: 100%;

}
.img1 img{
    width: 250px;
    height: 250px;
    border-radius: 100%;
    object-fit: cover;
}
.consultation-title{
    font-size: 20px;
    color: #009E8F;
    font-weight: 600;
}
.consultation-btn{
    width: 90%;
    margin-left: 5%;
    height: auto;
    font-weight: 600;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 5%;
}
@media(min-width: 562px){
    .img1 img{
        width: 180px;
        height: 180px;
    }
}

@media(min-width: 750px){
    .img1 img{
        width: 200px;
        height: 200px;
    }
}

@media(min-width: 920px){
    .img1 img{
        width: 220px;
        height: 220px;
    }
}

@media(min-width: 1070px){
    .img1 img{
        width: 230px;
        height: 230px;
    }
}

@media(min-width: 1200px){
    .img1 img{
        width: 250px;
        height: 250px;
    }
}
.consultation-input-area{
    position: relative;
}
.consultation-input{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    border-radius: 20px;
    outline: none;
    color: grey;
    padding-left: 2%;
    font-size: 16px;
}
.consultation-textarea{
    min-height: 149px;
}
.consultation-carousel-card{
    min-height: 600px;
}