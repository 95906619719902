.title-page{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.big-title-route{
    align-items: flex-start;
    width: 10%;
    padding-bottom: 5px;
    border-bottom: 8px solid #009E8F;
}
.calendar-icon{
    align-items: flex-end;
    color: #009E8F;
    font-size: 40px;
    cursor: pointer;
}
.tabs-panel{
    width: 100%;
    min-height: 50px;
}
.ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 20px;
    background: transparent;
    outline: none;
    cursor: pointer;
    min-width: 150px;
    max-width: 250px;
    text-align: center;
}
.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    box-shadow: -1px 1px 10px grey;
    border: 1px solid #EBEBEB;
    padding: 10px 50px;
}
.tab-1{
    border: 1px solid red;
}
.tab-title{
    color: grey;
    font-weight: 500;
}

.contest-img img{
    width: 100%;
    border-radius: 0;
}
.contest-title{
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 550;
    color: rgba(0,0,0,0.8);
}
.contest{
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    position: relative;
}
.right-arrow-icon{
    position: absolute;
    right: 0px;
    bottom: 0px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 20px;
    color: #009E8F;
    background-color: #E6E6E6;
    padding-top: 10px;
}
.title-contest{
    font-size: 30px;
    font-family: sans-serif;
    font-weight: 700;
}
@media(max-width: 576px) {
    .calendar-icon{
        font-size: 20px;
    }
    .title-contest{
        font-size: 18px;
    }


}
@media(min-width: 576px) {
    .calendar-icon{
        font-size: 24px;
    }

    .title-contest{
        font-size: 22px;
    }

}

@media(min-width: 768px) {
    .calendar-icon{
        font-size: 30px;
    }

    .title-contest{
        font-size: 24px;
    }
}

@media(min-width: 992px) {
    .calendar-icon{
        font-size: 30px;
    }

    .title-contest{
        font-size: 26px;
    }
}

@media(min-width: 1200px) {
    .calendar-icon{
        font-size: 36px;
    }

    .title-contest{
        font-size: 28px;
    }

}

.contest_card-desc{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    padding: 5px;
    font-weight: 500;
}