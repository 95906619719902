.title-content{
    display: flex;
    justify-content: center;
    width: 100%;
}
.ourmission-page{
    margin-bottom: 30px;
}
.home-page-title{
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin-top: 20px;
}
.title-bottom-linear{
    width: 10%;
    height: 7px;
    background-color: #009E8F;
    display: block;
}
.little-text{
    width: 90%;
    font-size: 18px;
    margin: 0 auto;
    font-weight: 500;
    font-family: Roboto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-content{
    width: 90%;
    margin-left: 5%;
    min-height: 180px;
    background-color: #F8F8F8;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    margin-bottom: 30px;
}
.icon-card{
    width: 25%;
    padding: 10px 10px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    height: auto;
}
.icon-card img{
    width: 120%;
    height: 80%;
}
.icon-bottom-line{
    width: 70%;
    height: 6px;
    background-color: #009E8F;
    position: absolute;
    bottom: -3px;
    left: 15%;
}
.text-card{
    width: 70%;
    align-items: flex-start;
}
.text-card-big-text{
    font-size: 18px;
    font-weight: 600;
    color: rgba(0,0,0,0.7);
    text-align: center;
}
.text-card-little-text{
    font-weight: 600;
    color: grey;
    font-size: 14px;
    text-align: center;
}
.mission-btn{
    width: 200px;
    padding: 10px;
    height: 45px;
    font-weight: normal;
    margin-bottom: 20px;
}
.mission-icon{
    font-size: 70px;
}
@media(max-width: 576px) {
    .home-page-title{
        font-size: 10px;
        margin-left: 10%;
    }
    .mission-icon{
        font-size: 30px;
    }


}
@media(min-width: 576px) {
    .home-page-title{
        font-size: 10px;
    }
    .mission-icon{
        font-size: 40px;
    }

}

@media(min-width: 768px) {
    .home-page-title{
        font-size: 20px;
    }
    .mission-icon{
        font-size: 45px;
    }
}

@media(min-width: 992px) {
    .home-page-title{
        font-size: 25px;
    }
    .mission-icon{
        font-size: 55px;
    }
}

@media(min-width: 1200px) {
    .home-page-title{
        font-size: 30px;
    }

}
.mission-btn:hover{
    color: #009E8F;
}
