.before_select{
    color: grey;
    margin-right: 10px;
}
.videos-card{
    width: 100%;
    padding: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    min-height: 350px;
    overflow-x: hidden;
    position: relative;
}
.little-text-resources{
    position: absolute;
    bottom: 0;
    left: 20%;
}
.video-title{
    font-weight: 600;
    font-size: 20px;
    padding: 0 10px;
    height: 120px;
    overflow: hidden;
}
.img-and-video{
    position: relative;
    width: 100%;
}
.select_area{
    display: flex;
    justify-content: left;
}
.select_area div{

}
.tab-title{
    font-size: 22px;
}
@media(max-width: 576px) {
    .tab-title{
        font-size: 16px;
    }



}
@media(min-width: 576px) {
    .tab-title{
        font-size: 18px;
    }

}

@media(min-width: 768px) {
    .tab-title{
        font-size: 20px;
    }
}

@media(min-width: 992px) {
    .tab-title{
        font-size: 22px;
    }
}

@media(min-width: 1200px) {


}
.img-and-video img{
    height: 200px;
    object-fit: cover;
    object-position: center center;
}


.video-desc,.video-title{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    padding: 5px;
    font-weight: 500;
}
.video-title{
    -webkit-line-clamp: 3;
    padding: 18px;
    text-align: justify;
}