.news-page{
    background-color: #F7FAFC;
    width: 100%;
    height: auto;
}

.title-content{
    display: flex;
    justify-content: center;
    width: 100%;
}
.home-page-title{
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
}
.title-bottom-linear{
    width: 10%;
    height: 7px;
    background-color: #009E8F;
    display: block;
}
.little-text{
    width: 90%;
    font-size: 18px;
    margin: 0 auto;
    font-weight: 500;
    font-family: Roboto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
 
}
.news-card{
    border-radius: 20px;
    padding: 0;
    min-height: 550px;
    background-color: white;
    box-shadow: 5px 2px 8px 2px lightgrey;
    position: relative;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10px;
}
.news-card img{
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center center;
}
.img-div{
    position: relative;
}
.gallery-icon{
    width: 60px;
    height: 60px;
    background-color: #E0E0E0;
    border-radius: 100%;
    position: absolute;
    bottom: -14%;
    left: 40%;
    text-align: center;
}
.gallery-icon img{
    width: 70%;
    margin-top: 15px;
}
.news-btn{
    margin-top: 50px;
}

.news-card-big-title{
    font-weight: 600;
    font-size: 20px;
    margin-top: 30px;
    padding: 0 10px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    min-height: 60px;
}
.news-card-little-text{
    font-size: 16px;
    color: grey;
    text-align: center;
    padding: 5px 15px;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.news-card-footer{
    width: 100%;
    height: 12%;
    position: absolute;
    left: 0;
    bottom: 0;
    /*background-color: red;*/
    border-radius: 0 0 20px 20px;
    border-top: 3px solid lightgrey;
    display: flex;
    justify-content: center;
}
.news-card-footer-content{
    display: flex;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    padding-top: 10px;
}
.calendar-icon{
    font-size: 20px;
    color: #009E8F;
}
.news-card-data{
    width: 80%;
    padding-top: 5px;
    font-weight: normal;
    align-items: flex-start;
    font-size: 18px;
}