.forum-title{
    width: 100%;
    border: none;
    margin-top: 50px;
    margin-left: -100%;
}
.forum{
    margin-right: 90%;
}
.active-question:active{
    color: #009E8F !important;
}
.active-question:hover{
    color: #009E8F !important;
}
.user-avatar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.user-avatar img{
    border-radius: 70px;
    height: 70px;
}
.fullname{
    font-size: 15px;
    font-weight: bold;
}
@media(max-width:530px){
    .user-avatar img{
        width: 50px;
        height: 50px;
    }
}