.title-content{
    display: flex;
    justify-content: center;
    width: 100%;
}
.home-page-title{
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
}
.news-card{
    margin-bottom: 30px;
}
.title-bottom-linear{
    width: 10%;
    height: 7px;
    background-color: #009E8F;
    display: block;
}
.little-text{
    width: 90%;
    font-size: 18px;
    margin: 0 auto;
    font-weight: 500;
    font-family: Roboto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-content{
    width: 90%;
    margin-left: 5%;
    min-height: 180px;
    background-color: #F8F8F8;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    margin-bottom: 30px;
}
.icon-card{
    width: 25%;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: white;
    position: relative;
    display: flex;
    justify-content: center;
    height: 110px;
}
.icon-card img{
    width: 100%;
    height: 100%;
}
.icon-bottom-line{
    width: 70%;
    height: 6px;
    background-color: #009E8F;
    position: absolute;
    bottom: -3px;
    left: 15%;
}
.text-card{
    width: 70%;
    align-items: flex-start;
}
.text-card-big-text{
    font-size: 18px;
    font-weight: 550;
    color: rgba(0,0,0,0.7);
}
.text-card-little-text{
    font-weight: 600;
    color: grey;
    font-size: 14px;
    text-align: center;
}
.mission-btn{
    width: 200px;
    padding: 10px;
    height: 45px;
    font-weight: normal;
    margin-bottom: 20px;
}
.grands-btn{
    position: absolute;
    bottom: 10px;
    margin-top: 10%;
}
.grand-text{
    min-height: 250px;
}
.grand-card{
    min-height: 650px;
}
@media(max-width: 576px){
    .grands-btn{
        margin-bottom: 10px;
        margin-top: 10%;
    }
    .grand-card{
        min-height: 680px;
    }
}
@media(max-width: 768px){
    .grand-card{
        min-height: 600px;
    }
}

@media(min-width: 992px) {
    .grand-card{
        min-height: 680px;
    }
}

@media(min-width: 1200px) {
    .grand-card{
        min-height: 620px;
    }

}

.grands-card-little-text{
    height: 200px;
    overflow: hidden;
    font-size: 15px;
    font-weight: 500;
    padding: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}