.sidebar_ul{
    list-style-type: none;
}
.sidebar_ul li{
    font-size: 15px;
    padding: 10px;
    color: white;
    font-family: Manrope;
    cursor: pointer;
    text-transform: uppercase;
}
.ant-drawer-wrapper-body{
    background-color: #009E8F;
}

