.faq-page{
    width: 100%;
    padding: 20px 10px;
}
.title-route{
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}
.big-title-route{
    font-size: 40px;
    font-weight: 600;
}
.page-title-lenta{
    background-color: #E5F0F9;
    width: 100%;
    height: 100%;
    padding: 20px;
    min-height: 60px;
    margin-bottom: 20px;
    border-radius: 0;
}
.discuss{
    width: 60px;
    height: 60px;
    background-color: #009E8F;
    color: white;
    text-align: center;
    padding-top: 10px;
    border-radius: 100%;
    font-size: 30px;
    margin-right: 15px;
}
.question{
    color: #009E8F;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding-top: 10px;
    cursor: pointer;
    margin-left: 5%;
}
.before-active{
    background-color: #F9F9F9;
    color: rgba(0,0,0,0.7);
}
.discuss-color-before{
    background-color: #E9E9E9;
    color: white;
}
.close{
    display: none;
}


@media(max-width: 576px) {
   .discuss{
       width: 30px;
       height: 30px;
       font-size: 15px;
       /*margin-right: 15px;*/
       padding-top: -10px;
   }
    .question{
        font-size: 15px;
    }
    .big-title-route{
        font-size: 18px;
    }
    .answer-1,.answer-2,.answer-3,.answer-4,.answer-5{
        font-size: 10px;
    }


}
@media(min-width: 576px) {
    .discuss{
        width: 40px;
        height: 40px;
        font-size: 18px;
        margin-right: 15px;
    }
    .question{
        font-size: 16px;
    }
    .big-title-route{
        font-size: 22px;
    }
    .answer-1,.answer-2,.answer-3,.answer-4,.answer-5{
        font-size: 12px;
    }
}

@media(min-width: 768px) {
    .discuss{
        width: 45px;
        height: 45px;
        font-size: 20px;
        margin-right: 15px;
    }
    .question{
        font-size: 17px;
    }
    .big-title-route{
        font-size: 30px;
    }
    .answer-1,.answer-2,.answer-3,.answer-4,.answer-5{
        font-size: 14px;
    }
}

@media(min-width: 992px) {
    .discuss{
        width: 50px;
        height: 50px;
        font-size: 24px;
        margin-right: 15px;
    }
    .question{
        font-size: 19px;
    }
    .big-title-route{
        font-size: 35px;
    }
    .answer-1,.answer-2,.answer-3,.answer-4,.answer-5{
        font-size: 15px;
    }
}

@media(min-width: 1200px) {

}
