.contact-icon{
    width: 40px;
    height: 40px;
    background-color: #009E8F;
    color: white;
    border-radius: 100%;
    padding: 8px 10px;
    font-size: 20px;
}
.tel{
    text-transform: uppercase;
    color: #009E8F;
    font-size: 24px;
}
.map iframe{
    width: 100%;
   min-height: 600px;
    border: none;
}