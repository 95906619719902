.menu-page{
    width: 100%;
    padding: 40px 100px;
    padding-bottom: 0;
    background-color: #009E8F;
    color: white;
    min-height: 200px;
    margin-top: -12px;
}
.logo-page{
    width: 100%;
}
.menu-list{
    padding-top: 30px;
}
.user-and-language{
    display: flex;
    justify-content: space-between;
}
.menu-items{
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    font-weight: 700;
    color: white;
    float: left;
    text-transform: uppercase;
}
.menu-dropdown{
    margin-top: -20px;
    background-color: #009E8F;
    color: white;
}
.menu-dropdown a{
    color: white;
    font-size: 18px;
}
.bars-icon{
    font-size: 30px;
    cursor: pointer;
    display: none;
    margin-left: 80%;
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 1000;
}
.have{
    font-size: 22px;
    font-weight: bold;
}
@media only screen and (max-width: 1300px){
    .menu-list{
        display: none;
    }
    .bars-icon{
        display: block;
    }
}
.to_sign_up{
    font-family: Manrope;
    color: #009E8F;
    cursor: pointer;
    font-size: 16px;
    margin-top: -4px;
}
@media(max-width: 576px) {
    .menu-page{
        min-height: 50px;
        padding: 20px 50px ;
    }
    .bars-icon{
        font-size: 18px;
    }
    .have{
        font-size: 14px;
    }
    .to_sign_up{
        font-size: 10px;
    }


}
@media(min-width: 576px) {
    .home-page-title{
        font-size: 10px;
    }
    .bars-icon{
        font-size: 20px;
    }
    .have{
        font-size: 16px;
    }
    .to_sign_up{
        font-size: 11px;
    }
    .menu-page{
        min-height: 50px;
        padding: 20px 50px ;
    }


}



@media(min-width: 768px) {
    .home-page-title{
        font-size: 20px;
    }
    .bars-icon{
        font-size: 26px;
    }
    .have{
        font-size: 18px;
    }
    .to_sign_up{
        font-size: 12px;
    }

}

@media(min-width: 992px) {
    .home-page-title{
        font-size: 25px;
    }
    .bars-icon{
        font-size: 32px;
    }
    .have{
        font-size: 20px;
    }
    .to_sign_up{
        font-size: 14px;
    }

}

@media(min-width: 1200px) {
    .home-page-title{
        font-size: 36px;
    }
    .to_sign_up{
        font-size: 15px;
    }


}

.ant-drawer-wrapper-body{
    background-color: #009E8F;
}


.sticky_menu{
    z-index: 1;
    margin-top: -46px;
    background: transparent;
}
.z_index{
    z-index: 2000;
}

.Sign_modal .ant-modal-content{
    position: relative;
    box-sizing: border-box;
    width: 70%;
    margin-left: 15%;
    padding-left: 7%;
    min-height: 450px;
}

.Sign_in_button{
    width: 58%;
    margin-left: 5%;
    background-color: #009E8F;
    border-radius: 10px;
}

.Sign_button:hover{
    background-color: #B2B8BF;
    border: none;
}
.remember_password{
    color: gray;
    font-family: Manrope;
}

.idGov{
    background-color: #08C784;
    border-radius: 20px;
    padding: 5px;
}
.idGov:hover{
    background-color: #009E8F;
    color: white;
}
.Sign_up_modal .ant-modal-content{
    width: 80%;
    margin-left: 10%;
    position: relative;
    height: auto;
    box-sizing: border-box;
    min-height: 900px;
}
.Sign_button{
    width: 60%;
    margin-left: 15%;
    background-color: #B2B8BF;
    border-radius: 10px;
    color: white;
}

.Sign_button:after{
    background-color: #B7BABE;
}
.Sign_button:hover{
    background-color: #B2B8BF;
    border: none;
}
.remember_password{
    color: gray;
    font-family: Manrope;
    width: 90%;
    overflow-x: hidden;
}
.to_sign_up{
    font-family: Manrope;
    color: #08C784;
    cursor: pointer;
    font-size: 16px;
    margin-top: -4px;
}
.idGov{
    background-color: #08C784;
}
.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #08C784;
    border-color: #08C784;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: white;
    border: 5px solid #08C784;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #08C784;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #08C784;
}

.Sign_up_modal .ant-modal-footer{
    padding: 10px 16px;
    padding-top: 50%;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    margin-top: 65%;
}

.LogoutCofirm .ant-modal-body{
    width: 80%;
}

.language_div{
    background-color: white;
    border-radius: 25px;
    color: #000;
    width: 100%;
    padding: 20px 0px;
}
.language_ul{
    margin-left: -10%;
}
.language_ul li{
    color: black;
    font-weight: 500;
    font-family: SansSerif;
    margin-right: 5%;
    width: 30%;
}
.language_ul li:nth-child(3n){
    margin-right: 0;
}
.second_ul_menu .hover_li:hover{
    border: none;
    color: black;
}
.language_div .language_ul .greenli{
    color: #00c580;
}
.signin{
    width: 40%;
}

.join-button{
    width: 80%;
    margin-left: 10%;
    background-color: transparent;
    outline: none;
    border-radius: 15px;
    color: white;
    font-size: 12px;
    padding: 4px;
    font-family: "Times New Roman";
    text-align: center;
}
.to_sign_up{
    font-size: 16px;
}

@media only screen and (max-width: 300px){
    .Sign_up_modal .ant-modal-content{
        min-height: 700px;
        width: 100%;
        margin-left: 0;
    }
}
.first_ul{
    position: absolute;
    right: -20px;
    top: 10%;
}
@media  only screen and(max-width:576px){
    .Sign_up_modal .ant-modal-content{
        min-width: 1000px;
    }
}
.Sign_in_button:hover h3{
    color: #009E8F;
}
#signBtn{
    font-size: 16px;
}
@media(max-width:720px){
    #signBtn{
        font-size: 12px;
    }
}
@media(max-width:530px){
    #signBtn{
        font-size: 12px;
        width: 50%;
        height: 35px;
        border-radius: 10px;
    }
}
@media(max-width:340px){
    #signBtn{
        font-size: 10px;
    }
}
