.footer-page{
    width: 100%;
    padding: 20px;
    background-color: #E5F0F9;
    padding-top: 50px;
}
.footer-first-text{
    width: 100%;
    font-weight: 500;
    padding: 20px;
    font-size: 16px;
    font-family: sans-serif;
}
.social-media{
    margin-top: 20px;
    width: 100%;
}
.social-media-icons{
    border-radius: 100%;
    background-color: white;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
    color: #009E8F;
}
.footer-ul ul li{
    font-size: 18px;
    font-weight: 600;
}