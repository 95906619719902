*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root{
    --mainColor:#009E8F,
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.button{
    cursor: pointer;
    background-color: #009E8F;
    color: white;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 18px;
    font-weight: 400;
    font-size: 18px;
    box-shadow: 2px 3px 3px lightgray;
}
.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.mainColor{
    color: #009E8F;
}
.w75{
    width: 75%;
}
.w100{
    width: 100%;
}
.p10{
    padding: 10px;
}
.page-title-content{
    width: 100%;
    height: auto;
}
.d-flex-center{
    display: flex;
    justify-content: center;
}
Link{
    color: transparent;
}

@media(min-width: 576px) {
    .container {
        max-width: 540px;
    }
    .button{
        width: 100px;
        height: 30px;
        font-size: 14px;
    }
}

@media(min-width: 768px) {
    .container {
        max-width: 720px;
    }

    .button{
        width: 120px;
        height: 32px;
        font-size: 15px;
    }
}

@media(min-width: 992px) {
    .container {
        max-width: 960px;
    }

    .button{
        width: 130px;
        height: 35px;
        font-size: 16px;
    }
}

@media(min-width: 1200px) {
    .container {
        max-width: 1100px;
    }
    .button{
        width: 150px;
        height: 40px;
        font-size: 18px;
        font-weight: 500;
    }

}
.button:hover{
    background-color: white;
    color: #009E8F !important;
    transition-duration: 1s;
}

ul{
    list-style-type: none;
}
ul li{
    font-size: 16px;
}
.fs-fw{
    font-size: 18px;
    font-weight: 600;
}