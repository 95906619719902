@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,600;0,700;0,800;1,900&family=Oxygen&display=swap');
.number{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    padding: 10px;
    padding-top: 20px;
    background-color: #E3E8F0;
}
.number p{
    font-size: 20px;
    font-weight: bold;
    color: #009E8F;
    padding-top: 40%;
}
.counter-text{
    font-size: 16px;
    font-weight: bold;
    color: #009E8F;
}
.third-1{
    width: 80%;
    padding: 10px;
    align-items: center;
    -webkit-align-content: center;
    text-align: center;
}
.relative{
    position: relative;
    display: flex;
    justify-content: center;
}
.icon-area{
    border-radius: 100%;
    background-color: #E1E6EE;
    color: #009E8F;
    font-size: 50px;
    padding-top: 10%;
    width: 120px;
    height: 120px;
}
.counter{
    position: absolute;
    left: 0;
    bottom: -5px;
    background-color: #009E8F;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    color: white;
    padding: 3px;
    text-align: center;
    font-weight: bold;
}
.counter-title{
    font-weight: bold;
    color: #009E8F;
    font-size: 18px;
    text-align: center;
    font-family: Nunito;
}
.about_main{
    width: 100%;
    height: auto;
    min-height: 90vh;
    position: relative;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
.about_main img{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center center;
    top: 0;
    left: 0;
}
.about_project-content{
    position: absolute;
    left: 20%;
    top: 10%;
    width: 60%;
    /*background-color: rgba(0,158,143,0.5);*/
    text-align: center;
    padding:40px 20px;
}
.about-title{
    font-size: 40px;
    color: white;
    font-weight: bold;
}
.about_little-text{
    color: white;
    font-family: sans-serif;
    font-weight: normal;
    font-size: 22px;
    word-spacing: 10px;
}
.join-card{
    min-height: 490px;
}
@media(max-width: 576px){
    .about-title{
        font-size: 20px;
    }
    .about_little-text{
        font-size: 12px;
    }
    .about_project-content{
        width: 80%;
        left: 10%;
    }
}
@media(max-width: 720px){
    .about-title{
        font-size: 25px;
    }
    .about_little-text{
        font-size: 14px;
    }
    .about_project-content{
        width: 80%;
        left: 10%;
    }
}

@media(max-width: 880px){
    .about-title{
        font-size: 30px;
    }
    .about_little-text{
        font-size: 16px;
    }
    .about_project-content{
        width: 70%;
        left: 15%;
    }
}

@media(max-width: 930px){
    .about-title{
        font-size: 35px;
    }
    .about_little-text{
        font-size: 18px;
    }
    .about_project-content{
        width: 70%;
        left: 15%;
    }
}

@media screen and (max-width: 1200px){
    .forum-item-span{
        height: 40px;
    }
}
@media screen and (max-width: 760px){
    .forum-item-span{
        height: 20px;
    }
}


