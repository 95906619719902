@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,600;0,700;0,800;1,900&family=Oxygen&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    overflow-x: hidden;
}
@media only screen and (min-width: 200px) {
    body{
        width: 100%;
        overflow-x: hidden;
    }
}
@media(min-width: 200px){
    body{
        width: 100%;
        overflow-x: hidden;
    }
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
:root{
    --mainColor:#009E8F,
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.button{
    cursor: pointer;
    background-color: #009E8F;
    color: white;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 18px;
    font-weight: 400;
    font-size: 18px;
    box-shadow: 2px 3px 3px lightgray;
}
.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.mainColor{
    color: #009E8F;
}
.w75{
    width: 75%;
}
.w100{
    width: 100%;
}
.p10{
    padding: 10px;
}
.page-title-content{
    width: 100%;
    height: auto;
}
.d-flex-center{
    display: flex;
    justify-content: center;
}
Link{
    color: transparent;
}

@media(min-width: 576px) {
    .container {
        max-width: 540px;
    }
    .button{
        width: 100px;
        height: 30px;
        font-size: 14px;
    }
}

@media(min-width: 768px) {
    .container {
        max-width: 720px;
    }

    .button{
        width: 120px;
        height: 32px;
        font-size: 15px;
    }
}

@media(min-width: 992px) {
    .container {
        max-width: 960px;
    }

    .button{
        width: 130px;
        height: 35px;
        font-size: 16px;
    }
}

@media(min-width: 1200px) {
    .container {
        max-width: 1100px;
    }
    .button{
        width: 150px;
        height: 40px;
        font-size: 18px;
        font-weight: 500;
    }

}
.button:hover{
    background-color: white;
    color: #009E8F !important;
    transition-duration: 1s;
}

ul{
    list-style-type: none;
}
ul li{
    font-size: 16px;
}
.fs-fw{
    font-size: 18px;
    font-weight: 600;
}
.big-post{
    width: 100%;
    position: relative;
    height: auto;
    padding-top: 200px;
    overflow: hidden;
}

.menu-dropdown{
    text-transform: uppercase;
}
.home-menu-slider{
   background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

.carousel-content{
    position: relative;
    top: 40%;
    /*left: 10%;*/
    width: 80%;
    height: auto;


}
.main-slider-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    display: block;
}
.carousel-page{
    /*position: relative;*/
    width: 100%;
    min-height: 90vh;
    overflow: hidden;
    top: -20%;
    left: 0;
}
.carousel-page .main-slider-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.slider-item{
    width: 100%;
    height: auto;
}
.menu-list{
    text-transform: uppercase;
    margin-top: 2%;
}

.carousel-title{
    font-weight: 700;
    width: 100%;
    font-size: 50px;
    color: #009E8F;
    text-shadow: 1px 1px 20px rgba(0,0,0,0.8);
}

.black-area{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    display: block;
}

.black-area{
    background-color: rgba(0,0,0,0.7);
}
.carousel-little-text{
    color: white;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
}
.carousel-button{
    cursor: pointer;
    background-color: #009E8F;
    color: white;
    width: auto;
    height: 60px;
    border: none;
    outline: none;
    border-radius: 25px;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 50px;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.6);
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
}
.carousel-dots{
    width: 15px;
    height: 15px;
    background-color: white;
    border: none;
    border-radius: 100%;
    margin-top: 80px;
    margin-bottom: 50px;
    position: absolute;
    bottom: 10px;
}

.slider-icon-bar{
    position: absolute;
    top: 0;
}

.logo-area{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}
.logo-area img{
    width: 70%;
    align-items: center;
}
.itsm-logo img{
    margin-left: -50%;
}
@media(min-width: 576px) {
    .carousel-title{
        font-size: 18px;
    }
    .carousel-little-text{
        font-size: 10px;
    }

}
@media(max-width: 576px) {
    .big-post{
        padding-top: 120px;
    }
    .carousel-content{
        top: 10%;
        margin-bottom: 50px;
    }
    .carousel-title{
        font-size: 20px;
        font-family: "Times New Roman";
    }
    .carousel-little-text{
        font-size: 10px;
    }
    .carousel-button{
        height: 30px;
        padding: 2px 20px;
        font-size: 10px;
    }
    .carousel-dots{
        width: 8px;
        height: 8px;
    }
    .black-area{
        min-height: 120vh;
    }
    .carousel-page{
        min-height: 70vh;
    }
    .home-menu-slider{
        position: relative;
        background-color: rgba(0,0,0,0.7);
    }
}

@media (min-width: 576px) {

    .big-post{
        padding-top: 120px;
    }
    .carousel-title{
        font-size: 32px;
    }
    .carousel-little-text{
        font-size: 18px;
    }

    .carousel-button{
        height: 35px;
        padding: 5px 25px;
        font-size: 12px;
    }

    .carousel-page{
        min-height: 100vh;
    }

}

@media(min-width: 576px) {

    .big-post{
        padding-top: 120px;
    }
    .carousel-title{
        font-size: 32px;
    }
    .carousel-little-text{
        font-size: 18px;
    }

    .carousel-button{
        height: 35px;
        padding: 5px 25px;
        font-size: 12px;
    }

    .carousel-page{
        min-height: 100vh;
    }

}

@media(min-width: 768px) {
    .big-post{
        padding-top: 140px;
    }
    .carousel-title{
        font-size: 40px;
    }
    .carousel-little-text{
        font-size: 20px;
    }

    .carousel-button{
        height: 40px;
        padding: 10px 30px;
        font-size: 13px;
    }
    .carousel-dots{
        width: 10px;
        height: 10px;
    }

    .carousel-page{
        min-height: 120vh;
    }

}

@media(min-width: 992px) {
    .big-post{
        padding-top: 160px;
    }
    .carousel-title{
        font-size: 45px;
    }
    .carousel-little-text{
        font-size: 22px;
    }

    .carousel-button{
        height: 50px;
        padding: 10px 40px;
        font-size: 15px;
    }
    .carousel-dots{
        width: 12px;
        height: 12px;
    }

    .carousel-page{
        min-height: 120vh;
    }

}

@media(min-width: 1200px) {
    .big-post{
        padding-top: 200px;
    }

    .carousel-page{
        min-height: 120vh;
    }

}
@media(min-width: 1400px) {
    .big-post{
        padding-top: 200px;
    }

    .carousel-page{
        min-height: 120vh;
    }

}


.menu-page{
    width: 100%;
    padding: 40px 100px;
    padding-bottom: 0;
    background-color: #009E8F;
    color: white;
    min-height: 200px;
    margin-top: -12px;
}
.logo-page{
    width: 100%;
}
.menu-list{
    padding-top: 30px;
}
.user-and-language{
    display: flex;
    justify-content: space-between;
}
.menu-items{
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    font-weight: 700;
    color: white;
    float: left;
    text-transform: uppercase;
}
.menu-dropdown{
    margin-top: -20px;
    background-color: #009E8F;
    color: white;
}
.menu-dropdown a{
    color: white;
    font-size: 18px;
}
.bars-icon{
    font-size: 30px;
    cursor: pointer;
    display: none;
    margin-left: 80%;
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 1000;
}
.have{
    font-size: 22px;
    font-weight: bold;
}
@media only screen and (max-width: 1300px){
    .menu-list{
        display: none;
    }
    .bars-icon{
        display: block;
    }
}
.to_sign_up{
    font-family: Manrope;
    color: #009E8F;
    cursor: pointer;
    font-size: 16px;
    margin-top: -4px;
}
@media(max-width: 576px) {
    .menu-page{
        min-height: 50px;
        padding: 20px 50px ;
    }
    .bars-icon{
        font-size: 18px;
    }
    .have{
        font-size: 14px;
    }
    .to_sign_up{
        font-size: 10px;
    }


}
@media(min-width: 576px) {
    .home-page-title{
        font-size: 10px;
    }
    .bars-icon{
        font-size: 20px;
    }
    .have{
        font-size: 16px;
    }
    .to_sign_up{
        font-size: 11px;
    }
    .menu-page{
        min-height: 50px;
        padding: 20px 50px ;
    }


}



@media(min-width: 768px) {
    .home-page-title{
        font-size: 20px;
    }
    .bars-icon{
        font-size: 26px;
    }
    .have{
        font-size: 18px;
    }
    .to_sign_up{
        font-size: 12px;
    }

}

@media(min-width: 992px) {
    .home-page-title{
        font-size: 25px;
    }
    .bars-icon{
        font-size: 32px;
    }
    .have{
        font-size: 20px;
    }
    .to_sign_up{
        font-size: 14px;
    }

}

@media(min-width: 1200px) {
    .home-page-title{
        font-size: 36px;
    }
    .to_sign_up{
        font-size: 15px;
    }


}

.ant-drawer-wrapper-body{
    background-color: #009E8F;
}


.sticky_menu{
    z-index: 1;
    margin-top: -46px;
    background: transparent;
}
.z_index{
    z-index: 2000;
}

.Sign_modal .ant-modal-content{
    position: relative;
    box-sizing: border-box;
    width: 70%;
    margin-left: 15%;
    padding-left: 7%;
    min-height: 450px;
}

.Sign_in_button{
    width: 58%;
    margin-left: 5%;
    background-color: #009E8F;
    border-radius: 10px;
}

.Sign_button:hover{
    background-color: #B2B8BF;
    border: none;
}
.remember_password{
    color: gray;
    font-family: Manrope;
}

.idGov{
    background-color: #08C784;
    border-radius: 20px;
    padding: 5px;
}
.idGov:hover{
    background-color: #009E8F;
    color: white;
}
.Sign_up_modal .ant-modal-content{
    width: 80%;
    margin-left: 10%;
    position: relative;
    height: auto;
    box-sizing: border-box;
    min-height: 900px;
}
.Sign_button{
    width: 60%;
    margin-left: 15%;
    background-color: #B2B8BF;
    border-radius: 10px;
    color: white;
}

.Sign_button:after{
    background-color: #B7BABE;
}
.Sign_button:hover{
    background-color: #B2B8BF;
    border: none;
}
.remember_password{
    color: gray;
    font-family: Manrope;
    width: 90%;
    overflow-x: hidden;
}
.to_sign_up{
    font-family: Manrope;
    color: #08C784;
    cursor: pointer;
    font-size: 16px;
    margin-top: -4px;
}
.idGov{
    background-color: #08C784;
}
.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #08C784;
    border-color: #08C784;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: white;
    border: 5px solid #08C784;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #08C784;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #08C784;
}

.Sign_up_modal .ant-modal-footer{
    padding: 10px 16px;
    padding-top: 50%;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    margin-top: 65%;
}

.LogoutCofirm .ant-modal-body{
    width: 80%;
}

.language_div{
    background-color: white;
    border-radius: 25px;
    color: #000;
    width: 100%;
    padding: 20px 0px;
}
.language_ul{
    margin-left: -10%;
}
.language_ul li{
    color: black;
    font-weight: 500;
    font-family: SansSerif;
    margin-right: 5%;
    width: 30%;
}
.language_ul li:nth-child(3n){
    margin-right: 0;
}
.second_ul_menu .hover_li:hover{
    border: none;
    color: black;
}
.language_div .language_ul .greenli{
    color: #00c580;
}
.signin{
    width: 40%;
}

.join-button{
    width: 80%;
    margin-left: 10%;
    background-color: transparent;
    outline: none;
    border-radius: 15px;
    color: white;
    font-size: 12px;
    padding: 4px;
    font-family: "Times New Roman";
    text-align: center;
}
.to_sign_up{
    font-size: 16px;
}

@media only screen and (max-width: 300px){
    .Sign_up_modal .ant-modal-content{
        min-height: 700px;
        width: 100%;
        margin-left: 0;
    }
}
.first_ul{
    position: absolute;
    right: -20px;
    top: 10%;
}
@media  only screen and(max-width:576px){
    .Sign_up_modal .ant-modal-content{
        min-width: 1000px;
    }
}
.Sign_in_button:hover h3{
    color: #009E8F;
}
#signBtn{
    font-size: 16px;
}
@media(max-width:720px){
    #signBtn{
        font-size: 12px;
    }
}
@media(max-width:530px){
    #signBtn{
        font-size: 12px;
        width: 50%;
        height: 35px;
        border-radius: 10px;
    }
}
@media(max-width:340px){
    #signBtn{
        font-size: 10px;
    }
}

.title-content{
    display: flex;
    justify-content: center;
    width: 100%;
}
.ourmission-page{
    margin-bottom: 30px;
}
.home-page-title{
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin-top: 20px;
}
.title-bottom-linear{
    width: 10%;
    height: 7px;
    background-color: #009E8F;
    display: block;
}
.little-text{
    width: 90%;
    font-size: 18px;
    margin: 0 auto;
    font-weight: 500;
    font-family: Roboto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-content{
    width: 90%;
    margin-left: 5%;
    min-height: 180px;
    background-color: #F8F8F8;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    margin-bottom: 30px;
}
.icon-card{
    width: 25%;
    padding: 10px 10px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    height: auto;
}
.icon-card img{
    width: 120%;
    height: 80%;
}
.icon-bottom-line{
    width: 70%;
    height: 6px;
    background-color: #009E8F;
    position: absolute;
    bottom: -3px;
    left: 15%;
}
.text-card{
    width: 70%;
    align-items: flex-start;
}
.text-card-big-text{
    font-size: 18px;
    font-weight: 600;
    color: rgba(0,0,0,0.7);
    text-align: center;
}
.text-card-little-text{
    font-weight: 600;
    color: grey;
    font-size: 14px;
    text-align: center;
}
.mission-btn{
    width: 200px;
    padding: 10px;
    height: 45px;
    font-weight: normal;
    margin-bottom: 20px;
}
.mission-icon{
    font-size: 70px;
}
@media(max-width: 576px) {
    .home-page-title{
        font-size: 10px;
        margin-left: 10%;
    }
    .mission-icon{
        font-size: 30px;
    }


}
@media(min-width: 576px) {
    .home-page-title{
        font-size: 10px;
    }
    .mission-icon{
        font-size: 40px;
    }

}

@media(min-width: 768px) {
    .home-page-title{
        font-size: 20px;
    }
    .mission-icon{
        font-size: 45px;
    }
}

@media(min-width: 992px) {
    .home-page-title{
        font-size: 25px;
    }
    .mission-icon{
        font-size: 55px;
    }
}

@media(min-width: 1200px) {
    .home-page-title{
        font-size: 30px;
    }

}
.mission-btn:hover{
    color: #009E8F;
}

.news-page{
    background-color: #F7FAFC;
    width: 100%;
    height: auto;
}

.title-content{
    display: flex;
    justify-content: center;
    width: 100%;
}
.home-page-title{
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
}
.title-bottom-linear{
    width: 10%;
    height: 7px;
    background-color: #009E8F;
    display: block;
}
.little-text{
    width: 90%;
    font-size: 18px;
    margin: 0 auto;
    font-weight: 500;
    font-family: Roboto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
 
}
.news-card{
    border-radius: 20px;
    padding: 0;
    min-height: 550px;
    background-color: white;
    box-shadow: 5px 2px 8px 2px lightgrey;
    position: relative;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10px;
}
.news-card img{
    border-radius: 20px 20px 0 0;
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center center;
}
.img-div{
    position: relative;
}
.gallery-icon{
    width: 60px;
    height: 60px;
    background-color: #E0E0E0;
    border-radius: 100%;
    position: absolute;
    bottom: -14%;
    left: 40%;
    text-align: center;
}
.gallery-icon img{
    width: 70%;
    margin-top: 15px;
}
.news-btn{
    margin-top: 50px;
}

.news-card-big-title{
    font-weight: 600;
    font-size: 20px;
    margin-top: 30px;
    padding: 0 10px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    min-height: 60px;
}
.news-card-little-text{
    font-size: 16px;
    color: grey;
    text-align: center;
    padding: 5px 15px;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 4 !important;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.news-card-footer{
    width: 100%;
    height: 12%;
    position: absolute;
    left: 0;
    bottom: 0;
    /*background-color: red;*/
    border-radius: 0 0 20px 20px;
    border-top: 3px solid lightgrey;
    display: flex;
    justify-content: center;
}
.news-card-footer-content{
    display: flex;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    padding-top: 10px;
}
.calendar-icon{
    font-size: 20px;
    color: #009E8F;
}
.news-card-data{
    width: 80%;
    padding-top: 5px;
    font-weight: normal;
    align-items: flex-start;
    font-size: 18px;
}
.title-content{
    display: flex;
    justify-content: center;
    width: 100%;
}
.home-page-title{
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
}
.news-card{
    margin-bottom: 30px;
}
.title-bottom-linear{
    width: 10%;
    height: 7px;
    background-color: #009E8F;
    display: block;
}
.little-text{
    width: 90%;
    font-size: 18px;
    margin: 0 auto;
    font-weight: 500;
    font-family: Roboto;
    margin-top: 20px;
    margin-bottom: 20px;
}
.card-content{
    width: 90%;
    margin-left: 5%;
    min-height: 180px;
    background-color: #F8F8F8;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    margin-bottom: 30px;
}
.icon-card{
    width: 25%;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: white;
    position: relative;
    display: flex;
    justify-content: center;
    height: 110px;
}
.icon-card img{
    width: 100%;
    height: 100%;
}
.icon-bottom-line{
    width: 70%;
    height: 6px;
    background-color: #009E8F;
    position: absolute;
    bottom: -3px;
    left: 15%;
}
.text-card{
    width: 70%;
    align-items: flex-start;
}
.text-card-big-text{
    font-size: 18px;
    font-weight: 550;
    color: rgba(0,0,0,0.7);
}
.text-card-little-text{
    font-weight: 600;
    color: grey;
    font-size: 14px;
    text-align: center;
}
.mission-btn{
    width: 200px;
    padding: 10px;
    height: 45px;
    font-weight: normal;
    margin-bottom: 20px;
}
.grands-btn{
    position: absolute;
    bottom: 10px;
    margin-top: 10%;
}
.grand-text{
    min-height: 250px;
}
.grand-card{
    min-height: 650px;
}
@media(max-width: 576px){
    .grands-btn{
        margin-bottom: 10px;
        margin-top: 10%;
    }
    .grand-card{
        min-height: 680px;
    }
}
@media(max-width: 768px){
    .grand-card{
        min-height: 600px;
    }
}

@media(min-width: 992px) {
    .grand-card{
        min-height: 680px;
    }
}

@media(min-width: 1200px) {
    .grand-card{
        min-height: 620px;
    }

}

.grands-card-little-text{
    height: 200px;
    overflow: hidden;
    font-size: 15px;
    font-weight: 500;
    padding: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.consultation-page{
    width: 100%;
    height: auto;
}
.big-title-1{
    margin-top: -100px;
}
.carousel-content1{
    margin-left: 10%;
}
.consultation-dots{
    border: 1px solid grey;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    /*margin-top: 30px;*/
}
.consultation-carousel{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.consultation-carousel-card{
    width: 90%;
    margin-left: 5%;
    min-height: 480px;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    border: none;
}
.avatar-and-tags{
    display: flex;
    justify-content: center;
}
.avatar{
    width: 30%;
    align-items: flex-start;

}
.carousel-images{
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
}
.consultation-carousel-title{
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
    font-family: sans-serif;

}
.tag-text{
    margin-top: -20px;
    padding: 0 10px;
    font-size: 18px;
}
.consultation-carousel-little-text{
    font-size: 18px;
    font-family: sans-serif;
    color: #505050;
    
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-align: justify;
    padding: 1px 10px;
}
.consultation-carousel-footer{
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 60%;
    height: 30px;
    background-color: #009E8F;
}
.slick-next, .slick-prev {
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 0;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    font-size: 30px;
    border: none;
    outline: none;
    right: 0;

}

.slick-prev{
    margin-left: 25px;
    z-index: 400;
}


.slick-dots:active {
    opacity: .75;
    color: #009E8F;
    font-size: 16px;
    text-shadow: 1px 2px 5px grey;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #009E8F;
    font-size: 16px;
    text-shadow: 1px 2px 5px grey;
}
.slick-dots li button {
    font-size: 16px;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
}

@media(min-width: 450px){
    .consultation-carousel-card{
        min-height: 450px;
    }
}
@media(min-width: 572px){
    .consultation-carousel-card{
        min-height: 500px;
    }
}
@media(min-width: 720px){
    .consultation-carousel-card{
        min-height: 550px;
    }
}
@media(min-width: 930px){
    .consultation-carousel-card{
        min-height: 580px;
    }
}
@media(min-width: 1020px){
    .consultation-carousel-card{
        min-height: 640px;
    }
    .consultation-btn{
        position: absolute;
        bottom: 20px;
    }
}
@media(min-width: 1220px){
    .consultation-carousel-card{
        min-height: 650px;
    }
    .consultation-btn{
        position: absolute;
        bottom: 20px;
    }
}
.slick-prev::before,.slick-next::before{
    color: darkgrey;
}
.slick-prev:before,.slick-next:before{
    font-size: 28px;
}
.main-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}




.forum-title{
    width: 100%;
    border: none;
    margin-top: 50px;
    margin-left: -100%;
}
.forum{
    margin-right: 90%;
}
.active-question:active{
    color: #009E8F !important;
}
.active-question:hover{
    color: #009E8F !important;
}
.user-avatar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.user-avatar img{
    border-radius: 70px;
    height: 70px;
}
.fullname{
    font-size: 15px;
    font-weight: bold;
}
@media(max-width:530px){
    .user-avatar img{
        width: 50px;
        height: 50px;
    }
}
.title-page{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.big-title-route{
    align-items: flex-start;
    width: 10%;
    padding-bottom: 5px;
    border-bottom: 8px solid #009E8F;
}
.calendar-icon{
    align-items: flex-end;
    color: #009E8F;
    font-size: 40px;
    cursor: pointer;
}
.tabs-panel{
    width: 100%;
    min-height: 50px;
}
.ant-tabs-tab {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 12px 0;
    font-size: 20px;
    background: transparent;
    outline: none;
    cursor: pointer;
    min-width: 150px;
    max-width: 250px;
    text-align: center;
}
.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    box-shadow: -1px 1px 10px grey;
    border: 1px solid #EBEBEB;
    padding: 10px 50px;
}
.tab-1{
    border: 1px solid red;
}
.tab-title{
    color: grey;
    font-weight: 500;
}

.contest-img img{
    width: 100%;
    border-radius: 0;
}
.contest-title{
    font-size: 20px;
    font-family: sans-serif;
    font-weight: 550;
    color: rgba(0,0,0,0.8);
}
.contest{
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    position: relative;
}
.right-arrow-icon{
    position: absolute;
    right: 0px;
    bottom: 0px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 20px;
    color: #009E8F;
    background-color: #E6E6E6;
    padding-top: 10px;
}
.title-contest{
    font-size: 30px;
    font-family: sans-serif;
    font-weight: 700;
}
@media(max-width: 576px) {
    .calendar-icon{
        font-size: 20px;
    }
    .title-contest{
        font-size: 18px;
    }


}
@media(min-width: 576px) {
    .calendar-icon{
        font-size: 24px;
    }

    .title-contest{
        font-size: 22px;
    }

}

@media(min-width: 768px) {
    .calendar-icon{
        font-size: 30px;
    }

    .title-contest{
        font-size: 24px;
    }
}

@media(min-width: 992px) {
    .calendar-icon{
        font-size: 30px;
    }

    .title-contest{
        font-size: 26px;
    }
}

@media(min-width: 1200px) {
    .calendar-icon{
        font-size: 36px;
    }

    .title-contest{
        font-size: 28px;
    }

}

.contest_card-desc{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    padding: 5px;
    font-weight: 500;
}
.before_select{
    color: grey;
    margin-right: 10px;
}
.videos-card{
    width: 100%;
    padding: 20px;
    height: auto;
    display: flex;
    justify-content: center;
    min-height: 350px;
    overflow-x: hidden;
    position: relative;
}
.little-text-resources{
    position: absolute;
    bottom: 0;
    left: 20%;
}
.video-title{
    font-weight: 600;
    font-size: 20px;
    padding: 0 10px;
    height: 120px;
    overflow: hidden;
}
.img-and-video{
    position: relative;
    width: 100%;
}
.select_area{
    display: flex;
    justify-content: left;
}
.select_area div{

}
.tab-title{
    font-size: 22px;
}
@media(max-width: 576px) {
    .tab-title{
        font-size: 16px;
    }



}
@media(min-width: 576px) {
    .tab-title{
        font-size: 18px;
    }

}

@media(min-width: 768px) {
    .tab-title{
        font-size: 20px;
    }
}

@media(min-width: 992px) {
    .tab-title{
        font-size: 22px;
    }
}

@media(min-width: 1200px) {


}
.img-and-video img{
    height: 200px;
    object-fit: cover;
    object-position: center center;
}


.video-desc,.video-title{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    padding: 5px;
    font-weight: 500;
}
.video-title{
    -webkit-line-clamp: 3;
    padding: 18px;
    text-align: justify;
}
.faq-page{
    width: 100%;
    padding: 20px 10px;
}
.title-route{
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}
.big-title-route{
    font-size: 40px;
    font-weight: 600;
}
.page-title-lenta{
    background-color: #E5F0F9;
    width: 100%;
    height: 100%;
    padding: 20px;
    min-height: 60px;
    margin-bottom: 20px;
    border-radius: 0;
}
.discuss{
    width: 60px;
    height: 60px;
    background-color: #009E8F;
    color: white;
    text-align: center;
    padding-top: 10px;
    border-radius: 100%;
    font-size: 30px;
    margin-right: 15px;
}
.question{
    color: #009E8F;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding-top: 10px;
    cursor: pointer;
    margin-left: 5%;
}
.before-active{
    background-color: #F9F9F9;
    color: rgba(0,0,0,0.7);
}
.discuss-color-before{
    background-color: #E9E9E9;
    color: white;
}
.close{
    display: none;
}


@media(max-width: 576px) {
   .discuss{
       width: 30px;
       height: 30px;
       font-size: 15px;
       /*margin-right: 15px;*/
       padding-top: -10px;
   }
    .question{
        font-size: 15px;
    }
    .big-title-route{
        font-size: 18px;
    }
    .answer-1,.answer-2,.answer-3,.answer-4,.answer-5{
        font-size: 10px;
    }


}
@media(min-width: 576px) {
    .discuss{
        width: 40px;
        height: 40px;
        font-size: 18px;
        margin-right: 15px;
    }
    .question{
        font-size: 16px;
    }
    .big-title-route{
        font-size: 22px;
    }
    .answer-1,.answer-2,.answer-3,.answer-4,.answer-5{
        font-size: 12px;
    }
}

@media(min-width: 768px) {
    .discuss{
        width: 45px;
        height: 45px;
        font-size: 20px;
        margin-right: 15px;
    }
    .question{
        font-size: 17px;
    }
    .big-title-route{
        font-size: 30px;
    }
    .answer-1,.answer-2,.answer-3,.answer-4,.answer-5{
        font-size: 14px;
    }
}

@media(min-width: 992px) {
    .discuss{
        width: 50px;
        height: 50px;
        font-size: 24px;
        margin-right: 15px;
    }
    .question{
        font-size: 19px;
    }
    .big-title-route{
        font-size: 35px;
    }
    .answer-1,.answer-2,.answer-3,.answer-4,.answer-5{
        font-size: 15px;
    }
}

@media(min-width: 1200px) {

}

.news-title{
    display: flex;
}
body{
    width: 100%;
    overflow-x: hidden;
}
.news_card-desc{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    text-align: justify;
}
.sidebar_ul{
    list-style-type: none;
}
.sidebar_ul li{
    font-size: 15px;
    padding: 10px;
    color: white;
    font-family: Manrope;
    cursor: pointer;
    text-transform: uppercase;
}
.ant-drawer-wrapper-body{
    background-color: #009E8F;
}


.img1{
    width: 100%;

}
.img1 img{
    width: 250px;
    height: 250px;
    border-radius: 100%;
    object-fit: cover;
}
.consultation-title{
    font-size: 20px;
    color: #009E8F;
    font-weight: 600;
}
.consultation-btn{
    width: 90%;
    margin-left: 5%;
    height: auto;
    font-weight: 600;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 5%;
}
@media(min-width: 562px){
    .img1 img{
        width: 180px;
        height: 180px;
    }
}

@media(min-width: 750px){
    .img1 img{
        width: 200px;
        height: 200px;
    }
}

@media(min-width: 920px){
    .img1 img{
        width: 220px;
        height: 220px;
    }
}

@media(min-width: 1070px){
    .img1 img{
        width: 230px;
        height: 230px;
    }
}

@media(min-width: 1200px){
    .img1 img{
        width: 250px;
        height: 250px;
    }
}
.consultation-input-area{
    position: relative;
}
.consultation-input{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    border-radius: 20px;
    outline: none;
    color: grey;
    padding-left: 2%;
    font-size: 16px;
}
.consultation-textarea{
    min-height: 149px;
}
.consultation-carousel-card{
    min-height: 600px;
}

.contact-icon{
    width: 40px;
    height: 40px;
    background-color: #009E8F;
    color: white;
    border-radius: 100%;
    padding: 8px 10px;
    font-size: 20px;
}
.tel{
    text-transform: uppercase;
    color: #009E8F;
    font-size: 24px;
}
.map iframe{
    width: 100%;
   min-height: 600px;
    border: none;
}
.profile-menu{
    width: 100%;
    height: auto;
    padding-top: 100px;
}
.menu-item{
    width: 100%;
    padding: 15px 50px;
    color: grey;
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
}
.active{
    background-color: #CCECE9;
    color: #009E8F;
}
.item-area{
    background-color: #F8F8F8;
    width: 100%;
    padding: 10px 50px;
}
.user{
    font-size: 100px;
    border-radius: 100%;
    color: grey;
}
@media(max-width: 576px) {
    .user{
        font-size: 30px;
    }
    .profile-menu{
        padding-top: 10px;
    }
}
@media(min-width: 576px) {
    .user{
        font-size: 40px;
    }
}

@media(min-width: 768px) {
   .user{
       font-size: 60px;
   }
}

@media(min-width: 992px) {
    .user{
        font-size: 80px;
    }
}

@media(min-width: 1200px) {


}
.profile-input{
    width: 100%;
    min-height: 50px;
    border-radius: 10px;
    border: 1px solid lightgrey;
    font-size: 20px;
    text-align: left;
    padding-left: 40px;
    color: grey;
}
.table-area{
    width: 90%;
    margin: 0 auto;
    border-radius: 20px;
    border: 3px solid #009E8F;
    margin-bottom: 20px;
    height: auto;
    overflow: hidden;
    z-index: 10;
}
.table-area table{
   width: 101%;
    margin: -2px;
    border: 1px solid grey;
}
.table-area table tr{
    font-size: 17px;
    font-weight: 600;
    color: grey;

}
.table-area table tr td{
    padding: 5px 15px;
    border: 1px solid grey;
}
.table-area table tr td b{
    color: rgba(0,0,0,0.6);
}

.title-area-forum{
    width: 100%;
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0px 0px 3px lightgrey, 0px 0px 4px lightgrey;
    border-radius: 10px;
    min-height: 40px;
    margin: 5px;
}
.question-area{
    min-height: 120px;
}
@media (max-width: 536px){
    .table-area
    {

        overflow-x: scroll;
    }
}
input{
    outline: none;
}
.avatar-img{
    width: 120px;
    height: 120px;
    border-radius: 100%;
    object-fit: cover;
}
.big-images{
    display: flex;
    justify-content: center;
    position: relative;
}

body{
    width: 100%;
    overflow-x: hidden;
}
.big-images img{
    width: 70%;
    border-radius: 20px;
}
.img-item{
    display: flex;
    justify-content: center;
    position: relative;
}
.img-item img{
    width: 80%;
    border-radius: 20px;
    margin-top: 20px;
}
.icon-profile{
    position: absolute;
    font-size: 100px;
    color: white;
    margin-top: 40%;
}
.little-icon{
    font-size: 40px;
    position: absolute;
    top: 30%;
    width: 30%;
    left: 45%;
    color: white;
    cursor: pointer;

}
.img-item img:hover{
    transition: 0.3s all;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
    border-radius: 30px;
}
@media (max-width: 576px) {
    .little-icon{
        top: 20%;
        left: 35%;
    }
}

.gallery_title{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-align: justify;
    text-align: justify;
    min-height: 60px;
}
.number{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    padding: 10px;
    padding-top: 20px;
    background-color: #E3E8F0;
}
.number p{
    font-size: 20px;
    font-weight: bold;
    color: #009E8F;
    padding-top: 40%;
}
.counter-text{
    font-size: 16px;
    font-weight: bold;
    color: #009E8F;
}
.third-1{
    width: 80%;
    padding: 10px;
    align-items: center;
    -webkit-align-content: center;
    text-align: center;
}
.relative{
    position: relative;
    display: flex;
    justify-content: center;
}
.icon-area{
    border-radius: 100%;
    background-color: #E1E6EE;
    color: #009E8F;
    font-size: 50px;
    padding-top: 10%;
    width: 120px;
    height: 120px;
}
.counter{
    position: absolute;
    left: 0;
    bottom: -5px;
    background-color: #009E8F;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    color: white;
    padding: 3px;
    text-align: center;
    font-weight: bold;
}
.counter-title{
    font-weight: bold;
    color: #009E8F;
    font-size: 18px;
    text-align: center;
    font-family: Nunito;
}
.about_main{
    width: 100%;
    height: auto;
    min-height: 90vh;
    position: relative;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
.about_main img{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center center;
    top: 0;
    left: 0;
}
.about_project-content{
    position: absolute;
    left: 20%;
    top: 10%;
    width: 60%;
    /*background-color: rgba(0,158,143,0.5);*/
    text-align: center;
    padding:40px 20px;
}
.about-title{
    font-size: 40px;
    color: white;
    font-weight: bold;
}
.about_little-text{
    color: white;
    font-family: sans-serif;
    font-weight: normal;
    font-size: 22px;
    word-spacing: 10px;
}
.join-card{
    min-height: 490px;
}
@media(max-width: 576px){
    .about-title{
        font-size: 20px;
    }
    .about_little-text{
        font-size: 12px;
    }
    .about_project-content{
        width: 80%;
        left: 10%;
    }
}
@media(max-width: 720px){
    .about-title{
        font-size: 25px;
    }
    .about_little-text{
        font-size: 14px;
    }
    .about_project-content{
        width: 80%;
        left: 10%;
    }
}

@media(max-width: 880px){
    .about-title{
        font-size: 30px;
    }
    .about_little-text{
        font-size: 16px;
    }
    .about_project-content{
        width: 70%;
        left: 15%;
    }
}

@media(max-width: 930px){
    .about-title{
        font-size: 35px;
    }
    .about_little-text{
        font-size: 18px;
    }
    .about_project-content{
        width: 70%;
        left: 15%;
    }
}

@media screen and (max-width: 1200px){
    .forum-item-span{
        height: 40px;
    }
}
@media screen and (max-width: 760px){
    .forum-item-span{
        height: 20px;
    }
}



.footer-page{
    width: 100%;
    padding: 20px;
    background-color: #E5F0F9;
    padding-top: 50px;
}
.footer-first-text{
    width: 100%;
    font-weight: 500;
    padding: 20px;
    font-size: 16px;
    font-family: sans-serif;
}
.social-media{
    margin-top: 20px;
    width: 100%;
}
.social-media-icons{
    border-radius: 100%;
    background-color: white;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
    color: #009E8F;
}
.footer-ul ul li{
    font-size: 18px;
    font-weight: 600;
}
html {
  height: 100%;
}

.anime {
  height: 100vh;
  background: #009E8F;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  position: relative;
  width: 75px;
  height: 100px;
}

.loader__bar {
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 50%;
  background: #fff;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.loader__bar:nth-child(1) {
  left: 0px;
  -webkit-transform: scale(1, 0.2);
          transform: scale(1, 0.2);
  -webkit-animation: barUp1 4s infinite;
          animation: barUp1 4s infinite;
}

.loader__bar:nth-child(2) {
  left: 15px;
  -webkit-transform: scale(1, 0.4);
          transform: scale(1, 0.4);
  -webkit-animation: barUp2 4s infinite;
          animation: barUp2 4s infinite;
}

.loader__bar:nth-child(3) {
  left: 30px;
  -webkit-transform: scale(1, 0.6);
          transform: scale(1, 0.6);
  -webkit-animation: barUp3 4s infinite;
          animation: barUp3 4s infinite;
}

.loader__bar:nth-child(4) {
  left: 45px;
  -webkit-transform: scale(1, 0.8);
          transform: scale(1, 0.8);
  -webkit-animation: barUp4 4s infinite;
          animation: barUp4 4s infinite;
}

.loader__bar:nth-child(5) {
  left: 60px;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-animation: barUp5 4s infinite;
          animation: barUp5 4s infinite;
}

.loader__ball {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  -webkit-animation: ball 4s infinite;
          animation: ball 4s infinite;
}

@-webkit-keyframes ball {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  5% {
    -webkit-transform: translate(8px, -14px);
            transform: translate(8px, -14px);
  }
  10% {
    -webkit-transform: translate(15px, -10px);
            transform: translate(15px, -10px);
  }
  17% {
    -webkit-transform: translate(23px, -24px);
            transform: translate(23px, -24px);
  }
  20% {
    -webkit-transform: translate(30px, -20px);
            transform: translate(30px, -20px);
  }
  27% {
    -webkit-transform: translate(38px, -34px);
            transform: translate(38px, -34px);
  }
  30% {
    -webkit-transform: translate(45px, -30px);
            transform: translate(45px, -30px);
  }
  37% {
    -webkit-transform: translate(53px, -44px);
            transform: translate(53px, -44px);
  }
  40% {
    -webkit-transform: translate(60px, -40px);
            transform: translate(60px, -40px);
  }
  50% {
    -webkit-transform: translate(60px, 0);
            transform: translate(60px, 0);
  }
  57% {
    -webkit-transform: translate(53px, -14px);
            transform: translate(53px, -14px);
  }
  60% {
    -webkit-transform: translate(45px, -10px);
            transform: translate(45px, -10px);
  }
  67% {
    -webkit-transform: translate(37px, -24px);
            transform: translate(37px, -24px);
  }
  70% {
    -webkit-transform: translate(30px, -20px);
            transform: translate(30px, -20px);
  }
  77% {
    -webkit-transform: translate(22px, -34px);
            transform: translate(22px, -34px);
  }
  80% {
    -webkit-transform: translate(15px, -30px);
            transform: translate(15px, -30px);
  }
  87% {
    -webkit-transform: translate(7px, -44px);
            transform: translate(7px, -44px);
  }
  90% {
    -webkit-transform: translate(0, -40px);
            transform: translate(0, -40px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@keyframes ball {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  5% {
    -webkit-transform: translate(8px, -14px);
            transform: translate(8px, -14px);
  }
  10% {
    -webkit-transform: translate(15px, -10px);
            transform: translate(15px, -10px);
  }
  17% {
    -webkit-transform: translate(23px, -24px);
            transform: translate(23px, -24px);
  }
  20% {
    -webkit-transform: translate(30px, -20px);
            transform: translate(30px, -20px);
  }
  27% {
    -webkit-transform: translate(38px, -34px);
            transform: translate(38px, -34px);
  }
  30% {
    -webkit-transform: translate(45px, -30px);
            transform: translate(45px, -30px);
  }
  37% {
    -webkit-transform: translate(53px, -44px);
            transform: translate(53px, -44px);
  }
  40% {
    -webkit-transform: translate(60px, -40px);
            transform: translate(60px, -40px);
  }
  50% {
    -webkit-transform: translate(60px, 0);
            transform: translate(60px, 0);
  }
  57% {
    -webkit-transform: translate(53px, -14px);
            transform: translate(53px, -14px);
  }
  60% {
    -webkit-transform: translate(45px, -10px);
            transform: translate(45px, -10px);
  }
  67% {
    -webkit-transform: translate(37px, -24px);
            transform: translate(37px, -24px);
  }
  70% {
    -webkit-transform: translate(30px, -20px);
            transform: translate(30px, -20px);
  }
  77% {
    -webkit-transform: translate(22px, -34px);
            transform: translate(22px, -34px);
  }
  80% {
    -webkit-transform: translate(15px, -30px);
            transform: translate(15px, -30px);
  }
  87% {
    -webkit-transform: translate(7px, -44px);
            transform: translate(7px, -44px);
  }
  90% {
    -webkit-transform: translate(0, -40px);
            transform: translate(0, -40px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}

@-webkit-keyframes barUp1 {
  0% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  40% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  90% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
}

@keyframes barUp1 {
  0% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  40% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  50% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  90% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  100% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
}

@-webkit-keyframes barUp2 {
  0% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  40% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  50% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  90% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  100% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
}

@keyframes barUp2 {
  0% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  40% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  50% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  90% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  100% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
}

@-webkit-keyframes barUp3 {
  0% {
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
  }
  100% {
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
  }
}

@keyframes barUp3 {
  0% {
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
  }
  100% {
    -webkit-transform: scale(1, 0.6);
            transform: scale(1, 0.6);
  }
}

@-webkit-keyframes barUp4 {
  0% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  40% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  50% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  90% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  100% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
}

@keyframes barUp4 {
  0% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  40% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
  50% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  90% {
    -webkit-transform: scale(1, 0.4);
            transform: scale(1, 0.4);
  }
  100% {
    -webkit-transform: scale(1, 0.8);
            transform: scale(1, 0.8);
  }
}

@-webkit-keyframes barUp5 {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  40% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  90% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes barUp5 {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  40% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  90% {
    -webkit-transform: scale(1, 0.2);
            transform: scale(1, 0.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.content {
  position: relative;
}

.content h2 {
  color: #fff;
  font-size: 30px;
  position: absolute;
  -webkit-transform: translate(-30%, 220%);
          transform: translate(-30%, 220%);
}

.content h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px White;
}

.content h2:nth-child(2) {
  color: #009E8F;
  -webkit-animation: animate 4s ease-in-out infinite;
          animation: animate 4s ease-in-out infinite;
}

@-webkit-keyframes animate {
  0%,
  100% {
    -webkit-clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
  }
  50% {
    -webkit-clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
  }
}

@keyframes animate {
  0%,
  100% {
    -webkit-clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 45%, 16% 44%, 33% 50%, 54% 60%, 70% 61%, 84% 59%, 100% 52%, 100% 100%, 0% 100%);
  }
  50% {
    -webkit-clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
            clip-path: polygon(0% 60%, 15% 65%, 34% 66%, 51% 62%, 67% 50%, 84% 45%, 100% 46%, 100% 100%, 0% 100%);
  }
}
/*# sourceMappingURL=new.css.map */
