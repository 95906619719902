.big-images{
    display: flex;
    justify-content: center;
    position: relative;
}

body{
    width: 100%;
    overflow-x: hidden;
}
.big-images img{
    width: 70%;
    border-radius: 20px;
}
.img-item{
    display: flex;
    justify-content: center;
    position: relative;
}
.img-item img{
    width: 80%;
    border-radius: 20px;
    margin-top: 20px;
}
.icon-profile{
    position: absolute;
    font-size: 100px;
    color: white;
    margin-top: 40%;
}
.little-icon{
    font-size: 40px;
    position: absolute;
    top: 30%;
    width: 30%;
    left: 45%;
    color: white;
    cursor: pointer;

}
.img-item img:hover{
    transition: 0.3s all;
    transform: scale(1.1);
    cursor: pointer;
    border-radius: 30px;
}
@media (max-width: 576px) {
    .little-icon{
        top: 20%;
        left: 35%;
    }
}

.gallery_title{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-align: justify;
    text-align: justify;
    min-height: 60px;
}