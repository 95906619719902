.profile-menu{
    width: 100%;
    height: auto;
    padding-top: 100px;
}
.menu-item{
    width: 100%;
    padding: 15px 50px;
    color: grey;
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
}
.active{
    background-color: #CCECE9;
    color: #009E8F;
}
.item-area{
    background-color: #F8F8F8;
    width: 100%;
    padding: 10px 50px;
}
.user{
    font-size: 100px;
    border-radius: 100%;
    color: grey;
}
@media(max-width: 576px) {
    .user{
        font-size: 30px;
    }
    .profile-menu{
        padding-top: 10px;
    }
}
@media(min-width: 576px) {
    .user{
        font-size: 40px;
    }
}

@media(min-width: 768px) {
   .user{
       font-size: 60px;
   }
}

@media(min-width: 992px) {
    .user{
        font-size: 80px;
    }
}

@media(min-width: 1200px) {


}
.profile-input{
    width: 100%;
    min-height: 50px;
    border-radius: 10px;
    border: 1px solid lightgrey;
    font-size: 20px;
    text-align: left;
    padding-left: 40px;
    color: grey;
}
.table-area{
    width: 90%;
    margin: 0 auto;
    border-radius: 20px;
    border: 3px solid #009E8F;
    margin-bottom: 20px;
    height: auto;
    overflow: hidden;
    z-index: 10;
}
.table-area table{
   width: 101%;
    margin: -2px;
    border: 1px solid grey;
}
.table-area table tr{
    font-size: 17px;
    font-weight: 600;
    color: grey;

}
.table-area table tr td{
    padding: 5px 15px;
    border: 1px solid grey;
}
.table-area table tr td b{
    color: rgba(0,0,0,0.6);
}

.title-area-forum{
    width: 100%;
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0px 0px 3px lightgrey, 0px 0px 4px lightgrey;
    border-radius: 10px;
    min-height: 40px;
    margin: 5px;
}
.question-area{
    min-height: 120px;
}
@media (max-width: 536px){
    .table-area
    {

        overflow-x: scroll;
    }
}
input{
    outline: none;
}
.avatar-img{
    width: 120px;
    height: 120px;
    border-radius: 100%;
    object-fit: cover;
}