.consultation-page{
    width: 100%;
    height: auto;
}
.big-title-1{
    margin-top: -100px;
}
.carousel-content1{
    margin-left: 10%;
}
.consultation-dots{
    border: 1px solid grey;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    /*margin-top: 30px;*/
}
.consultation-carousel{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}
.consultation-carousel-card{
    width: 90%;
    margin-left: 5%;
    min-height: 480px;
    padding: 10px;
    position: relative;
    border-radius: 10px;
    border: none;
}
.avatar-and-tags{
    display: flex;
    justify-content: center;
}
.avatar{
    width: 30%;
    align-items: flex-start;

}
.carousel-images{
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
}
.consultation-carousel-title{
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
    font-family: sans-serif;

}
.tag-text{
    margin-top: -20px;
    padding: 0 10px;
    font-size: 18px;
}
.consultation-carousel-little-text{
    font-size: 18px;
    font-family: sans-serif;
    color: #505050;
    
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-align: justify;
    padding: 1px 10px;
}
.consultation-carousel-footer{
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 60%;
    height: 30px;
    background-color: #009E8F;
}
.slick-next, .slick-prev {
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    font-size: 30px;
    border: none;
    outline: none;
    right: 0;

}

.slick-prev{
    margin-left: 25px;
    z-index: 400;
}


.slick-dots:active {
    opacity: .75;
    color: #009E8F;
    font-size: 16px;
    text-shadow: 1px 2px 5px grey;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #009E8F;
    font-size: 16px;
    text-shadow: 1px 2px 5px grey;
}
.slick-dots li button {
    font-size: 16px;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
}

@media(min-width: 450px){
    .consultation-carousel-card{
        min-height: 450px;
    }
}
@media(min-width: 572px){
    .consultation-carousel-card{
        min-height: 500px;
    }
}
@media(min-width: 720px){
    .consultation-carousel-card{
        min-height: 550px;
    }
}
@media(min-width: 930px){
    .consultation-carousel-card{
        min-height: 580px;
    }
}
@media(min-width: 1020px){
    .consultation-carousel-card{
        min-height: 640px;
    }
    .consultation-btn{
        position: absolute;
        bottom: 20px;
    }
}
@media(min-width: 1220px){
    .consultation-carousel-card{
        min-height: 650px;
    }
    .consultation-btn{
        position: absolute;
        bottom: 20px;
    }
}
.slick-prev::before,.slick-next::before{
    color: darkgrey;
}
.slick-prev:before,.slick-next:before{
    font-size: 28px;
}