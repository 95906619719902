*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    overflow-x: hidden;
}
@media only screen and (min-width: 200px) {
    body{
        width: 100%;
        overflow-x: hidden;
    }
}
@media(min-width: 200px){
    body{
        width: 100%;
        overflow-x: hidden;
    }
}
