.news-title{
    display: flex;
}
body{
    width: 100%;
    overflow-x: hidden;
}
.news_card-desc{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    padding: 5px;
    font-weight: 500;
    font-size: 16px;
    text-align: justify;
}