.big-post{
    width: 100%;
    position: relative;
    height: auto;
    padding-top: 200px;
    overflow: hidden;
}

.menu-dropdown{
    text-transform: uppercase;
}
.home-menu-slider{
   background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

.carousel-content{
    position: relative;
    top: 40%;
    /*left: 10%;*/
    width: 80%;
    height: auto;


}
.main-slider-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    display: block;
}
.carousel-page{
    /*position: relative;*/
    width: 100%;
    min-height: 90vh;
    overflow: hidden;
    top: -20%;
    left: 0;
}
.carousel-page .main-slider-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.slider-item{
    width: 100%;
    height: auto;
}
.menu-list{
    text-transform: uppercase;
    margin-top: 2%;
}

.carousel-title{
    font-weight: 700;
    width: 100%;
    font-size: 50px;
    color: #009E8F;
    text-shadow: 1px 1px 20px rgba(0,0,0,0.8);
}

.black-area{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    display: block;
}

.black-area{
    background-color: rgba(0,0,0,0.7);
}
.carousel-little-text{
    color: white;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
}
.carousel-button{
    cursor: pointer;
    background-color: #009E8F;
    color: white;
    width: auto;
    height: 60px;
    border: none;
    outline: none;
    border-radius: 25px;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 50px;
    box-shadow: 2px 5px 5px rgba(0,0,0,0.6);
    margin-bottom: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
}
.carousel-dots{
    width: 15px;
    height: 15px;
    background-color: white;
    border: none;
    border-radius: 100%;
    margin-top: 80px;
    margin-bottom: 50px;
    position: absolute;
    bottom: 10px;
}

.slider-icon-bar{
    position: absolute;
    top: 0;
}

.logo-area{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}
.logo-area img{
    width: 70%;
    align-items: center;
}
.itsm-logo img{
    margin-left: -50%;
}
@media(min-width: 576px) {
    .carousel-title{
        font-size: 18px;
    }
    .carousel-little-text{
        font-size: 10px;
    }

}
@media(max-width: 576px) {
    .big-post{
        padding-top: 120px;
    }
    .carousel-content{
        top: 10%;
        margin-bottom: 50px;
    }
    .carousel-title{
        font-size: 20px;
        font-family: "Times New Roman";
    }
    .carousel-little-text{
        font-size: 10px;
    }
    .carousel-button{
        height: 30px;
        padding: 2px 20px;
        font-size: 10px;
    }
    .carousel-dots{
        width: 8px;
        height: 8px;
    }
    .black-area{
        min-height: 120vh;
    }
    .carousel-page{
        min-height: 70vh;
    }
    .home-menu-slider{
        position: relative;
        background-color: rgba(0,0,0,0.7);
    }
}

@media (min-width: 576px) {

    .big-post{
        padding-top: 120px;
    }
    .carousel-title{
        font-size: 32px;
    }
    .carousel-little-text{
        font-size: 18px;
    }

    .carousel-button{
        height: 35px;
        padding: 5px 25px;
        font-size: 12px;
    }

    .carousel-page{
        min-height: 100vh;
    }

}

@media(min-width: 576px) {

    .big-post{
        padding-top: 120px;
    }
    .carousel-title{
        font-size: 32px;
    }
    .carousel-little-text{
        font-size: 18px;
    }

    .carousel-button{
        height: 35px;
        padding: 5px 25px;
        font-size: 12px;
    }

    .carousel-page{
        min-height: 100vh;
    }

}

@media(min-width: 768px) {
    .big-post{
        padding-top: 140px;
    }
    .carousel-title{
        font-size: 40px;
    }
    .carousel-little-text{
        font-size: 20px;
    }

    .carousel-button{
        height: 40px;
        padding: 10px 30px;
        font-size: 13px;
    }
    .carousel-dots{
        width: 10px;
        height: 10px;
    }

    .carousel-page{
        min-height: 120vh;
    }

}

@media(min-width: 992px) {
    .big-post{
        padding-top: 160px;
    }
    .carousel-title{
        font-size: 45px;
    }
    .carousel-little-text{
        font-size: 22px;
    }

    .carousel-button{
        height: 50px;
        padding: 10px 40px;
        font-size: 15px;
    }
    .carousel-dots{
        width: 12px;
        height: 12px;
    }

    .carousel-page{
        min-height: 120vh;
    }

}

@media(min-width: 1200px) {
    .big-post{
        padding-top: 200px;
    }

    .carousel-page{
        min-height: 120vh;
    }

}
@media(min-width: 1400px) {
    .big-post{
        padding-top: 200px;
    }

    .carousel-page{
        min-height: 120vh;
    }

}

